export default {
  getBannerList: 'GET /content/banner/list',
  createBanner: 'POST /content/banner/create',
  updateBanner: 'POST /content/banner/update',
  getDetailBanner: 'GET /content/banner/detail',
  getSelect: 'GET /content/list',
  getActivityList: 'GET /content/activity/list',
  createActivity: 'POST /content/activity/create',
  updateActivity: 'POST /content/activity/update',
  getDetailActivity: 'GET /content/activity/detail',
};


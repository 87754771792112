import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { render } from 'react-dom';
import { Dialog, Loading, Button } from '@tencent/spaui';

import api from 'api';
import { copyPath, getUserInfo, errorMsg } from '@utils';

const Dlog = ({ id, type, url, path, isProject, mingyuan }) => {
  console.log(mingyuan, 'mingyuan');
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);
  const [base64, setBase64] = useState(null);
  const [img, setImg] = useState('');
  const oid = useMemo(() => getUserInfo().org_id, []);
  const [appletUrl, setAppletUrl] = useState('');
  const typeMap = {
    potential: 'subpackage/pages/activity/potential/index',
    article: 'subpackage/pages/activity/article/index',
    case: 'pages/case/detail/index',
    detail: 'pages/info/info/index',
    collection: 'subpackage/pages/collect/potential/index',
  };

  const blob2base64 = blob => new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onerror = rej;
    reader.onload = () => {
      res(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  const handleClose = () => {
    render(null, document.querySelector('#pop-container'));
  };

  const handleDownload = () => {
    // ref.current.click()
    fetch(base64)
      .then(res => res.blob())
      .then(blob2base64)
      .then(setBase64)
      .then(() => {
        // console.log('image data:', base64)
        ref.current.click();
      })
      .catch(() => {
        errorMsg('图片下载失败，请尝试右键图片选择另存为');
      });
  };

  const handleCopy = () => {
    if (path) {
      return copyPath(false, false, path);
    }
    copyPath(id, type, appletUrl);
  };

  useEffect(() => {
    let appid = '';
    const apis = api('default');
    const gid = getUserInfo().group_id;
    const queryParams = [
      `targetPage=${typeMap[type]}`,
      `content_id=${id}`,
      `organization_id=${oid}`,
    ];
    if (/^wx/.test(id)) {
      queryParams.shift();
    }
    if (gid) {
      queryParams.push(`group_id=${gid}`);
    }
    new Promise((res) => {
      if (url) {
        res(url);
      } else {
        if (isProject) {
          const envURL = [
            'https://fang-dev.retail.qq.com',
          ];
          const IS_ENV = envURL.includes(window.location.origin) || process.env.NODE_ENV === 'development';
          const appID = IS_ENV ? 'wxe3371459f9fed74a' : 'wx5c21ce5787dc413b';
          const objData = {
            appid: appID,
            // organizationId: oid || isProject.pop(),
            organizationId: oid || '1_org',

          };
          const jsonObj = {
            targetPage: '',
          };
          if (isProject[0] === 'activity_id') {
            jsonObj.activity_id = isProject.pop();
            jsonObj.content_type = isProject[1];
            jsonObj.content_id = id;
            objData.page = type === 'potential'
              ? 'subpackage/pages/activity/potential/index'
              : 'subpackage/pages/activity/article/index';
          }
          if (isProject[0] === 'collection') {
            jsonObj.activity_id = isProject.pop();
            jsonObj.content_type = isProject[1];
            jsonObj.content_id = id;
            objData.page = type === 'collection'
              ? 'subpackage/pages/collect/potential/index'
              : '';
          }
          if (isProject[0] === 'content_id') {
            jsonObj.index = 0;
            jsonObj.content_id = id;
            objData.page = mingyuan ? 'pages/mingyuan/house-detail/index' : 'pages/info/info/index';
          }
          jsonObj.targetPage = `/${objData.page}`;
          let str = '';
          for (const k in jsonObj) {
            str += `${k}=${jsonObj[k]}&`;
          }
          const prom = apis.getProjectQr({
            ...objData,
            params: str.slice(0, str.length - 1),
          }).then((data) => {
            setAppletUrl(str.slice(0, str.length - 1));
            return data.data || data;
          });
          res(prom);
        } else {
          const prom = apis.getWxInfo({
            organization_id: oid,
            auth_type: 1,
          }).then((data) => {
            if (!data.auth_info) {
              errorMsg('请先授权小程序');
              throw new Error('小程序未授权');
            }
            appid = data.auth_info.appid;
            return apis.createScene({
              appid,
              organization_id: oid,
              url_param: queryParams.join('&'),
            });
          })
            .then(({ scene }) =>
            // const pageStr = `pages/entrance/index`
              apis.getQr({
                appid,
                scene,
                width: 300,
                auto_color: true,
                organization_id: oid,
              }))
            .then(data => data.file_url);
          res(prom);
        }
      }
    }).then((imgPath) => {
      imgPath = imgPath.replace(/^https?:/, '');
      console.log(imgPath, '二维码');
      setImg(imgPath);
      setBase64(imgPath);
      return fetch(imgPath).then(res => res.blob());
    })
      .then(blob2base64)
      .then(setBase64)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [oid, id, type, url, api]);

  return (
    <Dialog
      show
      escHide
      backdrop
      hideFooter
      title="小程序页面二维码"
      position="close"
      onClose={handleClose}
      portalClassName="c-code-dialog"
    >
      <div className="c-code__image">
        {
          loading
            ? (<Loading type="svg" />)
            : (<img src={img} alt="小程序二维码"/>)
        }
      </div>
      <a
        className="download-link"
        ref={ref}
        download={`qrcode-${id}`}
        href={base64}
      />
      <div className="c-code__btn">
        <Button disabled={loading} onClick={handleCopy}>复制小程序页面路径</Button>
        <Button
          displayType="primary"
          disabled={loading || !base64}
          onClick={handleDownload}
        >
          小程序页面二维码下载
        </Button>
      </div>
    </Dialog>
  );
};

export default Dlog;

export default {
  up2cos: 'POST /tool/cos/upload',
  getQr: 'POST /tool/wechat/unlimitedqrcode',
  getWxInfo: '/account/wechat/authinfo',
  createScene: 'POST /tool/wechat/scene/create',
  checkSSO: 'POST /account/login/sso/check',
  getOrgInfo: '/account/org/detail',
  getGrpInfo: '/account/group/detail',
  getGroupList: '/account/admin/list',
  changeCheckGroup: 'POST /account/admin/change',  // 切换项目
  getProjectQr: '/extra/qrcode/detail', // 获取项目二维码
  getAnnouncementnewone: 'GET /extra/announcement/newone',
  getSSOAccountList: '/account/sso/bindlist',
  SSOLogout: 'POST /account/sso/logout',
};

import { ReactComponent as phone } from './svg/phone.svg';
import { ReactComponent as home } from './svg/home.svg';
import { ReactComponent as buz } from './svg/buz.svg';
import { ReactComponent as mark } from './svg/mark.svg';
import { ReactComponent as merch } from './svg/merch.svg';
import { ReactComponent as sys } from './svg/sys.svg';
import { ReactComponent as center } from './svg/center.svg';

export { ReactComponent as Check } from './svg/check.svg';
export { ReactComponent as Add  } from './svg/add.svg';
export { ReactComponent as Delete  } from './svg/delete.svg';

export default {
  phone,
  home,
  buz,
  mark,
  merch,
  sys,
  center,
};

import React, { useEffect, useMemo, useState } from 'react';
// import { Menu } from "@tencent/tea-component"
import { useLocation, Link } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';
import { useDispatch, useSelector } from 'react-redux';
import { Menu as SMenu } from '@tencent/spaui';

import icons from 'common/icon';
import { getUnfilledRoutes } from 'app/routes';

// const { SubMenu, Item } = Menu

const getMenu = (routeData) => {
  // 找到路由为 '/' 的根路由节点
  let root = routeData.filter(item => item.path === '/');
  if (root.length !== 1) {
    return console.error('菜单获取失败');
  }
  root = root[0].routes;

  // 递归生成多级菜单
  const findMenu = (arr) => {
    // 如果传入一个 undefined 或者空数组，退出递归
    if (!arr || arr.length < 1) return [];
    const levelList = [];
    arr.forEach((item) => {
      // 不是菜单项的路由不用处理
      if (!item.isMenu) return;
      const node = {
        url: item.path,
        icon: item.icon,
        title: item.title,
      };
      node.sub = findMenu(item.routes);
      levelList.push(node);
    });
    return levelList;
  };
  const menuList = findMenu(root);
  // console.log(menuList)
  return menuList;
};

// Tea 的菜单组件
{ // eslint-disable-line
  // const MenuComponent = ({ routes }) => {
  //   const dispatch = useDispatch()
  //   const location = useLocation()
  //   const { pathname } = location

  //   const matchedRoutes = useMemo(() => {
  //     const matched = matchRoutes(routes, pathname)
  //     return new Set(matched.map(({ match }) => match.path))
  //   }, [pathname, routes])

  //   // 递归生成菜单组件
  //   const createMenu = arr => {
  //     if (!arr || arr.length < 1) return
  //     return arr.map(item => {
  //       const Icon = icons[item.icon]
  //       const title = (
  //         <>
  //           { Icon && <Icon /> }
  //           <span>{ item.title }</span>
  //         </>
  //       )
  //       const render = children => (
  //         <Link to={item.url}>{ children }</Link>
  //       )
  //       // 生成子菜单
  //       if (Array.isArray(item.sub) && item.sub.length) {
  //         return (
  //           <SubMenu title={title} key={item.url} defaultOpened={matchedRoutes.has(item.url)}>
  //             { createMenu(item.sub) }
  //           </SubMenu>
  //         )
  //       }
  //       return (
  //         <Item
  //           title={title}
  //           render={render}
  //           key={item.url}
  //           selected={matchedRoutes.has(item.url)}
  //         />
  //       )
  //     })
  //   }

  //   // url 改变后终止上一个 url 页面内的请求
  //   useEffect(() => {
  //     dispatch({
  //       type: 'CANCEL_REQUEST',
  //       payload: pathname
  //     })
  //   }, [pathname, dispatch])

  //   return (
  //     <div className="layout-menu">
  //       <div className="layout-menu__content">
  //         <Menu title="厂商名称">
  //           { createMenu(getMenu(routeList)) }
  //         </Menu>
  //       </div>
  //     </div>
  //   )
  // }
}

const list2data = (arr) => {
  if (!Array.isArray(arr) || arr.length < 1) return;
  return arr.map((item) => {
    const obj = {};
    if (Array.isArray(item.sub) && item.sub.length) {
      obj.data = list2data(item.sub);
    }
    obj.label = item.title;
    if (!Array.isArray(item.sub) || item.sub.length < 1) {
      obj.href = item.url;
    }
    if (item.icon) {
      const Icon = icons[item.icon];
      obj.icon = (
        <i className="spaui-menu-fontawesome fa fa-address-book" aria-hidden="true">
          <Icon />
        </i>
      );
    }
    obj.key = item.url;
    return obj;
  });
};

const custLink = (props) => {
  if (props.href) {
    const linkProp = { ...props, to: props.href };
    return (<Link { ...linkProp } />);
  }
  return (<span { ...props } />);
};

const SpaMenu = ({ routes }) => {
  const menus = useSelector(state => state.system.menu);
  const user = useSelector(s => s.user);
  const sequence = useSelector(state => state.sequence);
  const dispatchs = useDispatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const matched = matchRoutes(routes, pathname).map(({ match }) => match.path);
  const [openList, setOpenList] = useState([matched[matched.length - 2]]);
  const matchedRoutes = useMemo(() => new Set(matched), [matched]);

  // url 改变后终止上一个 url 页面内的请求
  useEffect(() => {
    dispatch({
      type: 'CANCEL_REQUEST',
      payload: pathname,
    });
  }, [pathname, dispatch]);
  const changeList = (event, data) => {
    if (data.label === '户型管理' || data.label === '活动管理') {
      for (const key in sequence) {
        if (Object.hasOwnProperty.call(sequence, key)) {
            sequence[key] = false;
        }
      }
      dispatchs({
        type: 'UPDATE_CITY',
        payload: sequence,
      });
    }
    console.log(event, data);
  };
  return (
    <div className="layout-menu">
      <p className="layout-menu__title">{user.bloc_name}</p>
      <SMenu
        LinkComponent={custLink}
        open={openList}
        onChange={changeList}
        data={list2data(getMenu(getUnfilledRoutes(menus)))}
        onOpen={(_, open) => {
          if (!Array.isArray(open)) open = [open];
          setOpenList(open);
        }}
        toggle={false}
        nodeIsSelected={(_, { key }) => matchedRoutes.has(key)}
      />
    </div>
  );
};

export default SpaMenu;

import { cancelable } from 'cancelable-promise';
import get from 'lodash/get';
import axios from 'axios';

import { dispatch } from 'app/store';
import { errorMsg } from '@utils';

const { CancelToken } = axios;

const noId = str => str.replace(/\s+rid:\s+[0-9a-z]+/, '');

let _token = sessionStorage.getItem('token');

export const setToken = (t) => {
  _token = (t || '').toString();
};

const REQUEST_PREFIX = {
  mock: '/mock',
  url: '/api/admin',
};

const apis = {};

const promiseSet = new Set();

let source = CancelToken.source();

const ioInstance = axios.create({
  cancelToken: source.token,
});

const intercept = (instance) => {
  // 拦截器，处理登录过期的情况
  instance.interceptors.response.use((res) => {
    if (res.data.logout) {
      dispatch && dispatch('LOGOUT');
    }
    if (res.config.url === '/api/admin/content/activity/list') {
      // const { list } = res.data.data;
      // if (Array.isArray(list)) {
      //   res.data.data.list = list.sort((a, b) => b.index - a.index);
      // }
    }
    return res;
  });
};
intercept(ioInstance);

ioInstance.interceptors.request.use((config) => {
  const countAndProjectList = [
    '/api/admin/extra/account/stafflist',
    '/api/admin/account/list',
  ];
  if (countAndProjectList.some(item => config.url.startsWith(item))) {
    const tmpp = location.href.split('?');
    if (tmpp.length > 1) {
      const params = tmpp.pop();
      const data = {};
      params.split('&').forEach((item) => {
        const Item = item.split('=');
        data[Item[0]] = decodeURIComponent(Item.pop()); // 解码中文
      });
      if (params.length) config.params = Object.assign({}, config.params, data);
    }
    console.log(config.params);
    if (config.params.extCur == 0) {
      delete config.params.current;
      delete config.params.extCur;
    }
  }
  return config;
});

export const cancel = () => {
  // 取消网络请求
  source.cancel();
  source = CancelToken.source();
  ioInstance.defaults.cancelToken = source.token;
  // 中断请求的 promise 链
  promiseSet.forEach(p => p.cancel());
  promiseSet.clear();
};

const parseUrl = (str) => {
  const methodReg = /^((post|get) +)?(?=[h/])/i;
  let method = 'get';
  let error = true;
  // 没匹配到就不会执行里面的方法
  const url = str.replace(methodReg, (mtd) => {
    error = false;
    method = mtd.trim().toLowerCase() || method;
    return '';
  });
  if (error) {
    throw Error('url 格式错误');
  }
  return { method, url };
};

// 封装 api 请求方法
export const io = uri => (data = {}, isMock = false, options = {}) => {
  if (typeof isMock === 'object' && isMock !== null) {
    options = isMock;
    isMock = false;
  } else if (typeof data === 'boolean') {
    isMock = data;
    data = {};
  }

  let { silence } = options;
  delete options.silence;

  const { method, url } = parseUrl(uri);
  const isRelative = url[0] === '/';
  const urlPrefix = REQUEST_PREFIX[isMock ? 'mock' : 'url'];
  const payloadName = ({
    post: 'data',
    get: 'params',
  })[method];
  const _headers = options.headers || {};
  if (_token) {
    _headers.Authorization = `Bearer ${_token}`;
  }
  const option = {
    method,
    url: `${isRelative ? urlPrefix : ''}${url}`,
    [payloadName]: data,
    ...options,
    headers: { ..._headers },
  };
  if (option.url === '/api/admin/extra/announcement/newone') {
    silence = true;
  }
  const urlList = [
    '/api/admin/extra/project/list',
    '/api/admin/extra/project/check/logs',
    '/api/admin/extra/account/stafflist',
  ];
  if (urlList.some(item => item === option.url)) {
    option.params.page = option.params.current;
    option.params.pageSize = option.params.page_size;
    delete option.params.current;
    delete option.params.page_size;
  }

  const urlList2 = [
    '/api/admin/extra/announcement/list',
    '/api/admin/extra/announcement/showlist',
  ];
  if (urlList2.some(item => item === option.url)) {
    option.params.pageSize = option.params.page_size;
    delete option.params.page_size;
  }

  let ioFn = ioInstance;
  if (options.canNotCancel === true) {
    ioFn = axios.create();
    intercept(ioFn);
  }

  const ioPrm = ioFn(option);

  const request = (options.canNotCancel === true ? ioPrm : cancelable(ioPrm))
    .then(({ data }) => data || { _info: 'NULL_DATA' })
    .then((data) => {
      // if (Number(data.code) === 0 || Number(data.code) === 500) { // 校验广告账号返回的特殊code
      //   return data;
      // }
      if (Number(data.code) === 500 || data.code === 'Fail') { // 校验广告账号返回的特殊code
        return data;
      }
      if (Number(data.code) === 50004) { // 项目详情页获取失败返回的特殊code,
        return Promise.reject(data);
      }
      if (data.code === 'Success' || Number(data.code) === 0) {
        return data.data || data;
      }
      if (Number(data.code) === -1 && data.message === '查询失败，请稍后再试') { // 项目详情页获取失败返回的特殊code,
        return data;
      }
      if (typeof request.cancel === 'function') {
        request.cancel();
      }
      !silence && errorMsg(data.message);
      return Promise.reject({ message: data.message });
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return 'REQUEST_CANCELED';
      } if (err.response) {
        const res = err.response;
        if (res.data && (res.data.code === 'ErrAuth' || Number(res.data.code) === 20000)) dispatch('LOGOUT');
        !silence && errorMsg(`${res.status} ${noId(get(res, 'data.message', res.statusText))}`);
        return Promise.reject({ message: noId(err.message) || 'NETWORK_ERROR_WITH_RESPONSE' });
      }
      !silence && errorMsg(noId(err.message) || 'NETWORK_ERROR');
      return Promise.reject(err);
    })
    .finally(() => promiseSet.delete(request));
  if (options.canNotCancel !== true) {
    promiseSet.add(request);
  }
  return request;
};

const getFuncModule = (obj) => {
  const moduleObj = {};
  Object.keys(obj).forEach((key) => {
    moduleObj[key] = io(obj[key]);
  });
  return moduleObj;
};

// 引入当前文件夹的 api 文件
const requireCurrentDir = ctx => ctx.keys().forEach((key) => {
  if (key === './index.js') return;
  const moduleName = get(key.match(/^\.\/([^/]+)\.js$/), 1);
  if (!moduleName) throw Error('api 文件名称错误');
  apis[moduleName] = getFuncModule(ctx(key).default || {});
});
requireCurrentDir(require.context('.', false, /\.js$/));

// 引入 Pages 目录下的页面级 api 文件
const requirePagesDir = ctx => ctx.keys().forEach((key) => {
  const moduleName = key.match(/\.\/([^/]+)/)[1].toLowerCase();
  apis[moduleName] = getFuncModule(ctx(key).default || {});
});
requirePagesDir(require.context('pages', true, /^\.\/([^/]+)\/api\.js$/));

const Api = (module) => {
  const fn = _mod => Api(_mod);
  const moduleContent = apis[module] || {};
  Object.keys(moduleContent).forEach((key) => {
    fn[key] = moduleContent[key];
  });
  return fn;
};

export default Api;

import React, {
  useState,
  useMemo,
  useRef,
  memo,
} from 'react';

import './style.scss';

const ColorSelector = ({ value, onChange, colors, max }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(value);
  const colorMap = useMemo(() => colors || {
    Color010: '#63b359',
    Color020: '#2c9f67',
    Color030: '#509fc9',
    Color040: '#5885cf',
    Color050: '#9062c0',
    Color060: '#d09a45',
    Color070: '#e4b138',
    Color080: '#ee903c',
    Color081: '#f08500',
    Color082: '#a9d92d',
    Color090: '#dd6549',
    Color100: '#cc463d',
    Color101: '#cf3e36',
    Color102: '#5E6671',
  }, [colors]);
  const ref = useRef();

  const len = Object.keys(colorMap).length;

  const width = useMemo(() => (8 + (30 * (max || Math.floor(len / 2)))), [max, len]);

  const handleSwitch = () => {
    setOpen(s => !s);
  };

  const handleBlur = () => {
    setOpen(false);
  };

  const ColorBlock = memo(({ color }) => {
    if (!color) {
      return (<div className="none-color"></div>);
    }

    const selectColor = () => {
      setSelected(color);
      ref.current.blur();
      onChange && onChange(color);
    };

    return (
      <div
        className="color-block"
        style={{ '--color': colorMap[color] }}
        data-color={color}
        onClick={selectColor}
      />
    );
  });

  return (
    <div className="c-color-selector spaui-select spaui-component" style={{ width }}>
      <div
        ref={ref}
        tabIndex="-1"
        onBlur={handleBlur}
        className={`selection-container selection-container-single ${open ? 'selection-with-drop' : ''}`}
      >
        <div className="selection-single placeholder" onClick={handleSwitch}>
          <span className="selection-single-text">
            <ColorBlock color={selected} />
          </span>
          <div className="selection-arrow">
            <svg width="16px" height="16px" viewBox="0 0 16 16">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                <polyline stroke="currentColor" points="4 6 8.5 10.5 13 6"></polyline>
              </g>
            </svg>
          </div>
        </div>
        <div className="selection-drop">
          {
            Object.keys(colorMap).map(key => (
              <ColorBlock color={key} key={key} />
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default memo(ColorSelector);

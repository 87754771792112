import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { renderRoutes as render } from 'react-router-config';

import Api from 'api';
import { dispatch } from './store';
import { Layout, Loading, AutoRedirect } from '@components';

const pathStore = new Map();

// const redirect = path => () => (<Redirect to={path}/>);

const pageRoutes = [
  {
    path: '/home',
    isMenu: true,
    title: '数据大盘',
    icon: 'home',
    routes: [
      {
        path: '/home/area-data',
        isMenu: true,
        title: '区域数据',
        component: 'Home/RegionalData',
        routes: [
          {
            path: '/home/area-data/propert-list',
            title: '楼盘列表',
            component: 'Home/propertList',
            routes: [
              {
                path: '/home/area-data/propert-list/groupExport-record',
                title: '导出记录',
                component: 'Home/ExportRecord',
              },
            ],
          },
          {
            path: '/home/area-data/export-record',
            title: '导出记录',
            component: 'Home/ExportRecord',
          },
        ],
      },
      {
        path: '/home/conversion-data',
        isMenu: true,
        title: '具体功能数据',
        component: 'Home/ConversionData',
      },
      {
        path: '/home/large-scale-data',
        isMenu: true,
        title: '大盘转化数据',
        component: 'Home/LargeScaleDataConversion',
        routes: [
          {
            path: '/home/large-scale-data/export-record',
            title: '导出记录',
            component: 'Home/ExportRecord',
          },
        ],
      },
    ],
  },
  {
    path: '/business',
    isMenu: true,
    title: '商机管理',
    icon: 'buz',
    routes: [
      {
        path: '/business/clue',
        isMenu: true,
        title: '线索管理',
        component: 'Business/Clue',
      },
      {
        path: '/business/session',
        isMenu: true,
        title: '会话管理',
        component: 'Business/Session',
      },
      {
        path: '/business/admin',
        isMenu: true,
        title: '置业顾问管理',
        component: 'Business/Staff',
      },
      {
        path: '/business/journal',
        isMenu: true,
        title: '案场核销日志',
        component: 'Business/Journal',

      },
    ],
  }, {
    path: '/merchant',
    isMenu: true,
    title: '商家管理',
    icon: 'merch',
    routes: [
      {
        path: '/merchant/organization',
        isMenu: true,
        title: '品牌商管理',
        component: 'Merchant/Organization',
        routes: [
          {
            path: '/merchant/organization/create',
            title: '创建品牌商',
            component: 'Merchant/Organization/Edit',
          },
          {
            path: '/merchant/organization/edit/:id',
            title: '编辑品牌商',
            component: 'Merchant/Organization/Edit',
          },
          {
            path: '/merchant/organization/import',
            title: '批量导入',
            component: 'Merchant/Organization/Import',
          },
          {
            path: '/merchant/organization/group/:type',
            title: '经销商列表',
            component: 'Merchant/Group',
          },
          {
            path: '/merchant/organization/staff/:type',
            title: '销售管理',
            component: 'Merchant/Staff',
          }, {
            path: '/merchant/organization/create-group/:type',
            title: '新建经销商',
            component: 'Merchant/Group/Edit',
          },
          {
            path: '/merchant/organization/edit-group/:type/:id',
            title: '编辑经销商',
            component: 'Merchant/Group/Edit',
          },
        ],
      },
      {
        path: '/merchant/staff',
        isMenu: true,
        title: '销售管理',
        component: 'Merchant/Staff',
      },
      {
        path: '/merchant/group',
        isMenu: true,
        title: '经销商管理',
        component: 'Merchant/Group',
        routes: [
          {
            path: '/merchant/group/create',
            title: '新建经销商',
            component: 'Merchant/Group/Edit',
          },
          {
            path: '/merchant/group/edit/:id',
            title: '编辑经销商',
            component: 'Merchant/Group/Edit',
          },
          {
            path: 'merchant/group/import',
            title: '批量导入',
            component: 'Merchant/Organization/Import',
          },
        ],
      },
    ],
  }, {
    path: '/marketing',
    isMenu: true,
    title: '营销管理',
    icon: 'mark',
    routes: [
      {
        path: '/marketing/propertiesList',
        isMenu: true,
        title: '楼盘管理',
        component: 'Marketing/propertiesList',
        routes: [
          {
            path: '/marketing/propertiesList/createDetails',
            title: '新建楼盘',
            component: 'Marketing/propertiesList/Details',
          },
          {
            path: '/marketing/propertiesList/details/:id',
            title: '楼盘详情配置',
            component: 'Marketing/propertiesList/Details',
          },
        ],
      },
      {
        path: '/marketing/collection',
        isMenu: true,
        title: '集合页管理',
        component: 'Marketing/Collection',
        routes: [
          {
            path: '/marketing/collection/create',
            title: '新建集合页',
            component: 'Marketing/Collection/Edit',
          },
          {
            path: '/marketing/collection/edit/:id',
            title: '编辑集合页',
            component: 'Marketing/Collection/Edit',
          },
        ],
      },
      {
        path: '/marketing/activity',
        isMenu: true,
        title: '活动管理',
        component: 'Marketing/Activity',
        routes: [
          {
            path: '/marketing/activity/create',
            title: '新建潜客直连',
            component: 'Marketing/Activity/Potential/Edit',
          }, {
            path: '/marketing/activity/edit/:id',
            title: '编辑潜客直连',
            component: 'Marketing/Activity/Potential/Edit',
          },
          {
            path: '/marketing/activity/create-ordinary',
            title: '新建普通活动',
            component: 'Marketing/Activity/Ordinary/Edit',
          }, {
            path: '/marketing/activity/edit-ordinary/:id',
            title: '编辑普通活动',
            component: 'Marketing/Activity/Ordinary/Edit',
          },
        ],
      }, {
        path: '/marketing/house-admin',
        isMenu: true,
        title: '户型管理',
        component: 'Marketing/House',
        routes: [
          {
            path: '/marketing/house-admin/create',
            title: '新建户型',
            component: 'Marketing/House/Edit',
          }, {
            path: '/marketing/house-admin/edit/:id',
            title: '编辑户型',
            component: 'Marketing/House/Edit',
          },
        ],
      }, {
        path: '/marketing/article',
        title: '文章素材',
        isMenu: true,
        component: 'Marketing/Article',
        routes: [
          {
            path: '/marketing/article/create',
            title: '新建文章',
            component: 'Marketing/Article/Edit',
          }, {
            path: '/marketing/article/edit/:id',
            title: '编辑文章',
            component: 'Marketing/Article/Edit',
          },
        ],
      },
    ],
  }, {
    path: '/applet',
    isMenu: true,
    title: '小程序设置',
    icon: 'sys',
    routes: [
      {
        path: '/applet/banner',
        isMenu: true,
        title: 'banner设置',
        component: 'Applet/Banner',
        routes: [
          {
            // path: '/applet/banner/create',
            title: 'banner创建',
            component: 'Applet/Banner/Edit',
          },
        ],
      }, {
        path: '/applet/activity',
        isMenu: true,
        title: '活动配置',
        component: 'Applet/Activity',
        routes: [
          {
            // path: '/applet/banner/create',
            title: '创建活动',
            component: 'Applet/Activity/Edit',
          },
        ],
      }, {
        path: '/applet/customize',
        isMenu: true,
        title: '个性化外观配置',
        component: 'Applet/Customize',
        routes: [
          {
            path: '/applet/customize/home',
            title: '首页设置',
            component: 'Applet/Customize/Home',
          },
        ],
      },
    ],
  },
  {
    path: '/project-center',
    isMenu: true,
    title: '项目中心',
    icon: 'buz',
    routes: [
      {
        path: '/project-center/project',
        isMenu: true,
        title: '项目管理',
        component: 'project-center/project',
        routes: [
          {
            path: '/project-center/project/key',
            title: '项目申请密钥',
            component: 'project-center/project/key',
          },
          {
            path: '/project-center/project/application/edit/:id',
            title: '项目申请编辑',
            component: 'project-center/project/Application',
          },
        ],
      },
      {
        path: '/project-center/houses',
        isMenu: true,
        title: '楼盘管理',
        component: 'project-center/houses',
        routes: [
          {
            path: '/project-center/houses/application/details',
            title: '楼盘详情',
            component: 'Marketing/propertiesList/Details',
          },
        ],
      },
      {
        path: '/project-center/admin',
        isMenu: true,
        title: '置业顾问管理',
        component: 'Business/Staff',
      },
      {
        path: '/project-center/journal',
        isMenu: true,
        title: '案场核销日志',
        component: 'Business/Journal',
      },
      {
        path: '/project-center/internal',
        isMenu: true,
        title: '自然流量-内部楼盘',
        component: 'project-center/internal',
        routes: [
          {
            path: '/project-center/internal/application/details',
            title: '内部楼盘详情',
            component: 'Marketing/propertiesList/Details',
          },
        ],
      },
      {
        path: '/project-center/bright',
        isMenu: true,
        title: '自然流量-明源楼盘',
        component: 'project-center/bright',
        routes: [
          {
            path: '/project-center/bright/application/details',
            title: '明源楼盘详情',
            component: 'Marketing/propertiesList/Details',
          },
        ],
      },
    ],
  },
  {
    path: '/system',
    isMenu: true,
    title: '系统设置',
    icon: 'sys',
    routes: [
      {
        path: '/system/roletemp',
        isMenu: true,
        title: '角色模板',
        component: 'System/Role',
        routes: [
          {
            path: '/system/roletemp/create',
            title: '新建角色模板',
            component: 'System/Role/Edit',
          },
          {
            path: '/system/roletemp/edit/:id',
            title: '编辑角色模板',
            component: 'System/Role/Edit',
          }, {
            path: '/system/roletemp/detail/:id',
            title: '角色模板详情',
            component: 'System/Role/Detail',
          },
        ],
      },
      {
        path: '/system/bind',
        isMenu: true,
        title: '广告账号绑定',
        component: 'System/AdAccount',
      },
      {
        path: '/system/account',
        isMenu: true,
        title: '账号管理',
        component: 'System/Account',
        routes: [
          {
            path: '/system/account/create',
            title: '新建账号',
            component: 'System/Account/Edit',
          }, {
            path: '/system/account/edit/:id',
            title: '编辑账号',
            component: 'System/Account/Edit',
          },
        ],
      },
      {
        path: '/system/team',
        isMenu: true,
        title: '团队设置',
        component: 'System/Team',
        routes: [
          {
            path: '/system/team/employee',
            title: '员工管理',
            component: 'System/Team/Employee',
            routes: [
              {
                path: '/system/team/employee/create',
                title: '新建账号',
                component: 'System/Team/Employee/Edit',
              }, {
                path: '/system/team/employee/edit/:id',
                title: '编辑账号',
                component: 'System/Team/Employee/Edit',
              },
            ],
          },
          {
            path: '/system/team/manage',
            title: '团队管理',
            component: 'System/Team/Manage',
          }, {
            path: '/system/team/link',
            title: '楼盘团队关联',
            component: 'System/Team/Link',
          },
        ],
      },
      {
        path: '/system/distribute',
        isMenu: true,
        title: '线索分配设置',
        component: 'System/LeadsDistribute',
      },
      {
        path: '/system/announcement',
        isMenu: true,
        title: '公告管理',
        component: 'System/Announcement',
        routes: [
          {
            path: '/system/announcement/create',
            title: '新建公告',
            component: 'System/Announcement/Edit',
          }, {
            path: '/system/announcement/edit/:id',
            title: '编辑公告',
            component: 'System/Announcement/Edit',
          },
        ],
      },
      {
        path: '/system/viewannment',
        // isMenu: true,
        title: '公告列表',
        component: 'System/Viewannment',
        routes: [
          {
            path: '/system/viewannment/detail/:id',
            title: '公告详情',
            component: 'System/Viewannment/Detail',
          },
        ],
      },
      {
        path: '/system/authorize',
        isMenu: true,
        title: '小程序授权',
        component: 'System/Authorize',
      },
      {
        path: '/system/center',
        isMenu: true,
        title: '个人中心',
        component: 'System/Center',
      },
      {
        path: '/system/lprrate',
        isMenu: true,
        title: 'LPR利率管理',
        component: 'System/LPRrate',
      },
      {
        path: '/system/goufangjie',
        isMenu: true,
        title: '购房节主页',
        component: 'Marketing/Goufangjie',
      },
      {
        path: '/system/privacyAgreement',
        isMenu: true,
        title: '隐私弹窗设置',
        component: 'System/PrivacyAgreement'
      }
    ],
  },
];

// 避免重复加载，例如 create 和 edit 页面使用同一个文件
const load = (routePath, componentPath) => (props) => {
  const { match, route } = props;
  // console.log('test:', match.params)
  const role = useSelector(({ user }) => user.type);
  const module = route.path.match(/^\/([^/]+)/)[1];
  // 向页面组件注入 api 和 dispatch
  const pageProps = { ...props, api: Api(module), dispatch, role };
  // const componentPath = componentStore[routePath]
  if (match.isExact) {
    // 匹配到当前路径但是没有找到对应的页面组件
    // 是有二级菜单的一级菜单，跳转到菜单下面的第一个页面
    if (!componentPath) {
      return (<Redirect to={route.routes[0].path || '/'}/>);
    }
    // 尝试调用缓存的页面
    let LazyComponent = null;
    if (pathStore.has(routePath)) {
      LazyComponent = pathStore.get(routePath);
    } else {
      // 利用 React 的特性，切片加载页面文件
      LazyComponent = lazy(() =>
        /**
         * 巨坑的一个地方：
         * import里面无法单独只使用一个变量，会导致找不到模块：
         * https://segmentfault.com/a/1190000015648036
         *
         * 无法使用模板字符串，babel-eslint@10.0.1+会报错
         * 虽然降级到 babel-eslint@8.0.1可以解决这个问题，
         * 但是会引入新的问题：Fragments 会无法被识别
         */
        // eslint-disable-next-line
         import('pages/' + componentPath));
      // 缓存页面
      pathStore.set(routePath, LazyComponent);
    }
    return (
      <Loading>
        <LazyComponent {...pageProps} />
      </Loading>
    );
  }
  // 匹配到子路径，渲染子路由页面
  const childrenRoutes = route.routes || [];
  // if (childrenRoutes.length < 1) {
  //   console.log(route)
  //   throw Error('子路由为空')
  // }
  const childrenProps = { ...pageProps };
  const oldPropKeys = [
    'history',
    'location',
    'match',
    'route',
    'staticContext',
    'api',
    'dispatch',
  ];
  oldPropKeys.forEach((key) => {
    delete childrenProps[key];
  });
  return render(childrenRoutes, childrenProps);
};

const fillComponent = (arr) => {
  if (!arr || arr.length < 1) return;
  arr.forEach((item) => {
    fillComponent(item.routes, item.path);
    const { component } = item;
    if (component && typeof component !== 'string') {
      return;
    }
    // 有页面组件的不用管
    // if (item.component) return
    item.component = load(item.path, component);
  });
  /**
   * 这里对于 404 的处理比较纠结
   * 全部直接重定向到 /404 还是直接渲染 404 的页面
   * 重定向的好处是不用管 404 在根路由还是在 / 的下一级
   * 直接渲染可以保持 url 不变，但是需要注意 404 的层级
   */
  // arr.push({ component: redirect('/404') })
  // if (path) {
  //   arr.push({
  //     path: `${path}/*`,
  //     component: () => '404 page'
  //   })
  // }
  return arr;
};

export const getAuthedRoutes = (perm) => {
  const authed = {
    0: { routes: [] },
  };
  const unauthed = {};
  const flat = (arr) => {
    if (!arr || arr.length < 1) return;
    arr.forEach((item) => {
      flat(item.routes);
      const { title, path } = item;
      unauthed[title + path] = { ...item };
      delete unauthed[title + path].routes;
    });
  };
  flat(pageRoutes);
  perm.forEach((pitem) => {
    const { id, index, name, url } = pitem;
    const pid = pitem.parent_id;
    const item = unauthed[name + url];
    if (item) {
      item.id = id;
      item.index = index;
      item.pid = pid;
    }
  });
  Object.keys(unauthed).forEach((key) => {
    const { id } = unauthed[key];
    if (id) {
      authed[id] = unauthed[key];
    }
  });
  Object.keys(authed).forEach((key) => {
    if (+key === 0) return;
    const item = authed[key];
    const { pid } = item;
    delete item.pid;
    if (!authed[pid]) {
      console.log('错误的路由节点:', pid);
      return;
    }
    if (authed[pid].routes) {
      authed[pid].routes.push(item);
    } else {
      authed[pid].routes = [item];
    }
    // if (authed[pid].routes) {
    //   authed[pid].routes.push(item);
    // } else {
    //   authed[pid].routes = [item];
    // }
  });
  const pRoutes = authed[0].routes;
  const sort = (arr, parentPath) => {
    if (!arr) return;
    arr.sort((a, b) => {
      const i = a.index;
      const j = b.index;
      // delete a.index;
      // delete b.index;
      return i - j;
      // return j - i;
    });
    arr.forEach((item) => {
      // delete item.id;
      sort(item.routes, item.path);
    });
    arr.push({
      path: `${parentPath}/*`.replace('//', '/'),
      component: () => '暂无权限',
    });
  };
  sort(pRoutes, '/');
  return pRoutes;
};

export const getUnfilledRoutes = perm => [
  {
    path: '/login',
    exact: true,
    component: AutoRedirect,
  },
  {
    path: '/',
    component: (props) => {
      if (props.isExact) return (<Redirect to={props.route.routes[0].path}/>);
      return (<Layout {...props} dispatch={dispatch} />);
    },
    routes: [
      {
        path: '/404',
        exact: true,
        component: () => '404 page',
      }, {
        path: '/',
        exact: true,
        component: AutoRedirect,
      },
      ...getAuthedRoutes(perm),
    ],
  },
  {
    path: '/project-center/project/key',
    exact: true,
    component: 'project-center/project/key',
  },
  {
    path: '/project-center/project/application/edit/:id',
    exact: true,
    component: 'project-center/project/Application',
  },
  {
    path: '/project-center/project/application',
    exact: true,
    component: 'project-center/project/Application',
  },
  {
    path: '/project-center/bind',
    exact: true,
    component: 'project-center/project/Bind',
  },
];

export const getFilledRoutes = perm => fillComponent(getUnfilledRoutes(perm));

export const getRoutes = (logined, perm) => {
  if (logined) return getFilledRoutes(perm);
  // 未登录时将所有地址重定向至登录页
  return fillComponent([
    {
      path: '/login',
      exact: true,
      component: 'Login',
    },
    {
      path: '/project-center/project/key',
      exact: true,
      component: 'project-center/project/key',
    },
    {
      path: '/project-center/project/application',
      exact: true,
      component: 'project-center/project/Application',
    },
    {
      path: '/project-center/project/application/edit/:id',
      exact: true,
      component: 'project-center/project/Application',
    },
    {
      path: '/project-center/bind',
      exact: true,
      component: 'project-center/project/Bind',
    },
    {
      path: '/*',
      component: () => (
        <Redirect to={{
          pathname: '/login',
          search: '',
        }}/>
      ),
    },
  ]);
};

/*
 * @Author: 菲菲呀
 * @Date: 2021-04-08 11:47:54
 * @LastEditors: 菲菲呀
 * @LastEditTime: 2021-04-15 14:51:18
 * @Description:
 */
export default {
  login: 'POST /account/login',
  getSSO: '/account/login/sso',
  sendCode: 'POST /account/noauth/sendcode',
  checkcode: 'POST /account/noauth/checkcode',
  updatePwd: 'POST /account/noauth/updatepwd',
  ssoLogin: 'POST /account/login/sso/login',
};

export default {
  getDaPan: '/get/dapan',
  getList: '/dapandata/list',
  // 服务商列表
  getCompanyList: '/account/company/list',
  // 查询区域数据概览数据
  getSummary: '/data/area/summary',
  // 查询区域数据里服务商列表数据
  getAnalysis: '/data/area/company/analysis',
  analysis: 'POST /data/export/area/company/group/analysis',
  // 查询区域数据里某服务商楼盘列表数据
  companyAnalysis: '/data/area/company/group/analysis',
  // 省份列表
  provincelist: '/account/company/provincelist',
  // 区域列表
  arealist: '/account/company/arealist',
  // 导出列表
  exportList: '/data/export/list',
  // 执行服务商下载任务
  exportAnalysis: 'POST /data/export/area/company/analysis',
  // 执行楼盘下载任务
  exportGroupAnalysis: 'POST /data/export/area/company/group/analysis',
  // 下载文件
  download: '/data/export/download',
  // 一键授权弹窗数据统计
  getAuthPhone: '/data/statistics/auth_phone',
  // 弹窗数据统计
  getPop: '/data/statistics/pop',
  // 指定广告主弹窗数据统计
  getSpecifiedPop: '/data/statistics/specified_pop',
  // 落地页数据统计
  getStatistics: '/data/statistics/content',
  // 落地页数据统计导出
  getStatisticsContent: '/data/statistics/content/export',
  // 意向表单数据统计
  getIntentionForm: '/data/statistics/intention_form',
  // 大盘数据-意向表单数据统计
  getIntentionFormDetail: '/data/statistics/intention_form_detail',

};

import React, { useState } from 'react';

import './index.scss';

function Nav({ navList, changeActiveNav }) {
  const [active, setActive] = useState(0);
  const changeAvtive = (i) => {
    setActive(i);
    changeActiveNav(i);
  };

  return (
            <div className="nav-container">
                {
                    navList.map((item, i) => <div className={item.value === active ? 'active' : ''}
                            key={i}
                            onClick={() => changeAvtive(item.value)}>
                            {item.title}
                        </div>)
                }
            </div>
  );
}

export default Nav;

export default {
  getOrgList: 'GET /account/org/list',
  orgCreate: 'POST /account/org/create',
  orgDetail: 'GET /account/org/detail',
  orgUpdate: 'POST /account/org/update',
  getGroupList: 'GET /account/group/list', // 经销商列表
  createGroup: 'POST /account/group/create', // 经销商创建
  getGroupDetail: 'GET /account/group/detail', // 经销商详情
  GroupUpdate: 'POST /account/group/update', // 经销商编辑
  getAccountList: 'GET /account/list', // 销售列表
  getMapAll: 'GET /account/map/all', // 所有省区
  getMapCity: 'GET /account/map/getchildren', // 所有省区  查找市区  地区
};

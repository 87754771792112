import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Modal, Justify } from '@tencent/tea-component';
import './index.scss';

const prefix = 'image-previewer';

/**
 * 图片预览
 */
function ImagePreview(props) {
  const { style, previewSrc, className, previewTitle, children } = props;
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div
        onClick={() => setVisible(true)}
        className={classNames(className, `${prefix}-content`)}
        style={style}
      >
        {children}
      </div>
      <Modal
        disableCloseIcon
        className={`${prefix}-dialog`}
        visible={visible}
        onClose={() => setVisible(false)}
        onExited={() => setVisible(false)}
      >
        <div className={`${prefix}-dialog-header`}>
          <Justify
            left={
              <h3 className={`${prefix}-dialog-header-title`}>
                {previewTitle}
              </h3>
            }
            right={
              <Button
                type="icon"
                icon="close"
                htmlType="button"
                onClick={() => setVisible(false)}
              />
            }
          />
        </div>
        <Modal.Body className={`${prefix}-dialog-body`}>
          <img src={previewSrc} alt="" />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImagePreview;

import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Dialog } from '@tencent/spaui';
import { message } from '@tencent/tea-component';
import copy from 'copy-to-clipboard';
import api from 'api';
import { CodePop } from '@components';
import Alert from '@components/NoAdAccountAlert';

export default {};

export const encode = obj => btoa(unescape(encodeURIComponent(JSON.stringify(obj))));

export const decode = (str) => {
  let result = {};
  try {
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    result = JSON.parse(decodeURIComponent(escape(atob(str))));
  } catch (e) {
    console.log('decode failed:', str);
  }
  return result;
};

export const success = (msg) => {
  message.success({ content: msg });
};

export const errorMsg = (msg) => {
  message.error({ content: msg });
};

/**
 * @param {string} name 文件名
 * @param {string[]} suffixes 文件后缀组成的数组
 * @returns {boolean} 正则校验结果
 */
// 需求单：https://tapd.woa.com/70100120/prong/stories/view/1070100120117483723
export const isValidFileName = (name, suffixes) => {
  // 根据传入的数组，拼出匹配文件后缀的正则表达式
  const suffixesPattern = suffixes.map(item => `\\.${item}`).join('|');
  // regex形如 /^[\u4e00-\u9fa5a-zA-Z0-9]+(.png|.jpg)$`)
  const regex = new RegExp(`^[\\u4e00-\\u9fa5a-zA-Z0-9]+(${suffixesPattern})$`);
  return regex.test(name);
};

export const copyPath = (id, type, path) => {
  const typeMap = {
    // potential: 'subpackage/pages/activity/potential/index',
    potential: 'pages/entrance/index',
    // article: 'subpackage/pages/activity/article/index',
    article: 'pages/entrance/index',
    case: 'pages/case/detail/index',
    detail: 'pages/entrance/index',
    collection: 'pages/entrance/index',
  };
  console.log(type, '------type------');
  // const flag = type === 'detail' ? '?' : '/';
  // if (path && copy(`${typeMap[type]}${flag}${path}`)) return success('小程序页面路径复制成功');
  if (path && copy(`${typeMap[type]}?${path}`)) return success('小程序页面路径复制成功');
  if (!id) {
    return errorMsg('内容 ID 获取失败');
  }
  const userInfo = decode(sessionStorage.getItem('_cache_user_info'));
  const querys = [`content_id=${id}`];
  if (userInfo.group_id) {
    querys.push(`group_id=${userInfo.group_id}`);
  }
  if (userInfo.org_id) {
    querys.push(`organization_id=${userInfo.org_id}`);
  }
  const pathStr = `pages/entrance/index?targetPage=${typeMap[type]}&${querys.join('&')}`;
  if (copy(pathStr)) {
    success('小程序页面路径复制成功');
  }
};

const checkRGB = (n) => {
  n = parseInt(n);
  if (typeof n !== 'number' || n < 0 || n > 255) {
    throw Error('Invalid rgb value');
  }
  return true;
};

export const grb2hex = (rgb) => {
  const { r, g, b } = rgb;
  if (checkRGB(r) && checkRGB(g) && checkRGB(b)) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  }
  return '#ffffff';
};

export function loadScript(src, callback, charset) {
  const el = document.createElement('script');
  if (callback) {
    const fn = function () {
      const s = this.readyState;
      if (!s || (s && (s === 'loaded' || s === 'complete'))) {
        callback(src);
      }
    };
    el.onload = fn;
    el.onerror = fn;
    el.onreadystatechange = fn;
  }
  el.type = 'text/javascript';
  el.src = src;
  el.async = 'true';
  if (charset) {
    el.charset = charset;
  }
  document.getElementsByTagName('head')[0].appendChild(el);
}

// 弹窗确认
export const confirm = (title, cb) => {
  const container = document.body.lastElementChild;
  const close = () => {
    const dialog = document.getElementsByClassName('util-dialog-container')[0];
    if (!dialog) return;
    dialog.parentNode.innerHTML = '';
  };

  const handleSuccess = () => {
    close();
    cb && cb();
  };

  render(
    <Dialog
      type="normal"
      title={title}
      iconType="remind"
      className="spaui-dialog-confirm-normal"
      portalClassName="util-dialog-container"
      show={true}
      onClose={close}
      onCancel={close}
      onSubmit={handleSuccess}
    >
      <div style={{ display: 'none' }}></div>
    </Dialog>,
    container,
  );
};

// 展示二维码弹窗
export const showCode = (id, type, path, isProject, mingyuan) => {
  const dialogContainer = document.querySelector('#pop-container');
  if (type === false) {
    render(<CodePop url={id} path={path} isProject={isProject || false} mingyuan={mingyuan}/>, dialogContainer);
    return id;
  }
  render(<CodePop id={id} type={type} path={path} isProject={isProject} mingyuan={mingyuan}/>, dialogContainer);
  return id;
};

export const getUserInfo = () => {
  const infoStr = sessionStorage.getItem('_cache_user_info');
  if (!infoStr) return {};
  return decode(infoStr);
};

// 校验正整数
export const checkInt = (params, canZero = false) => {
  if (!params) {
    return true;
  }
  if (Number(params) === 0 && canZero) {
    return true;
  }
  const match = (/^[1-9]*[1-9][0-9]*$/.test(params));
  if (!match) {
    message.error({
      content: '请输入正整数',
    });
    return false;
  }
  return true;
};

// 校验2位小数
export const checkFloat = (params, canZero = false) => {
  if (!params) {
    return true;
  }
  if (Number(params) === 0 && canZero) {
    return true;
  }
  const match = (/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(params));
  if (match) return true;
  if (!match) {
    message.error({
      content: '只能输入正整数或带两位小数',
    });
    return false;
  }
  return true;
};
export const getQueryString = (key) => {
  let after = window.location.search;
  // key存在先通过search取值如果取不到就通过hash来取
  after = after.substr(1) || window.location.hash.split('?')[1];
  if (after) {
    const reg = new RegExp(`(^|&)${key}=([^&]*)(&|$)`);
    const r = after.match(reg);
    if (r != null) {
      return  decodeURIComponent(r[2]);
    }

    return null;
  }
};
// 统计字符
export const getStringLength = (val) => {
  const text = val;
  let len = 0;
  for (let i = 0; i < text.length; i++) {
    if (text.charCodeAt(i) > 127) {
      len += 2;
    } else {
      len += 1;
    }
  }
  return len;
};

// 未绑定广告账号弹窗提示
export const noAdAccountAlert = (userId) => {
  const cacheKey = `_cache_no_ad_account_alert_read_${userId}`;
  if (window.localStorage.getItem(cacheKey) === 'true') return;

  const anchor = document.createElement('div');
  const handleClose = () => {
    unmountComponentAtNode(anchor);
    window.localStorage.setItem(cacheKey, 'true');
  };
  const props = { cacheKey, handleClose };
  render(<Alert {...props} />, anchor);
};

const LastStepConfirm = (props) => {
  return (
    <Dialog
      show
      type="normal"
      title={null}
      closeButton={false}
      onCancel={props.close}
      onSubmit={props.cb}
      style={{ textAlign: 'center', width: 420 }}
      footerStyle={{ textAlign: 'center', padding: 20 }}
      bodyStyle={{ fontSize: '16px', fontWeight: 'bold', lineHeight: '30px' }}
    >
      返回修改后，会重新扫码，确定要返回修改吗？
    </Dialog>
  );
};

export const confirmLogout = (_cb = (() => {})) => {
  const anchor = document.createElement('div');
  const close = () => unmountComponentAtNode(anchor);
  const cb = () => {
    close();
    _cb();
  };
  const props = { close, cb };
  render(<LastStepConfirm {...props} />, anchor);
};

export const logout = () => {
  const ticket = localStorage.getItem('_cache_xunda_ticket');
  return api('default').SSOLogout({ xunda_ticket: ticket || 'null' });
};

export const getSSOLoginUrl = (pathname) => {
  const redirectURL = `${window.location.origin}/?target=${pathname}`;
  // const redirectURL = `https://fang-dev.retail.qq.com/?target=${pathname}`;
  const loginURL = `https://sso.e.qq.com/login/hub?service_tag=78&sso_redirect_uri=${encodeURIComponent(redirectURL)}`;
  return loginURL;
};

export const goToSSOLogin = (pathname) => {
  const loginURL = getSSOLoginUrl(pathname);
  window.location.href = loginURL;
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Auto = () => {
  const path = useSelector(({ system }) => system.entryPath);
  // console.log('auto redirect:', path)
  // return (<Redirect to={path === '/login' ? '/' : path} />)
  return (<Redirect to={path} />);
};

export default Auto;

import React, { useState } from 'react';
import { Dialog, Radio } from '@tencent/spaui';

import './style.scss';

const Alert = (props) => {
  const [read, setRead] = useState(false);

  const handleClose = () => {
    if (!read) return;
    if (typeof props.handleClose !== 'function') return;
    props.handleClose();
  };

  return (
    <Dialog
      hideHeader
      type="normal"
      submitLabel="关闭"
      cancelButton={false}
      onSubmit={handleClose}
      style={{ width: 625 }}
      className="c-no-ad-account-alert"
    >
      <br />
      <br />
      <p>您的项目还未绑定广告投放账号，请先绑定广告账号后再编辑信息。</p>
      <p>绑定位置: 系统设置-广告账号绑定</p>
      <br />
      <br />
      <Radio
        checked={read}
        onChange={() => setRead((r) => !r)}
      >
        我知道了，以后不需要再提示
      </Radio>
    </Dialog>
  );
};

export default Alert;

import React, { useState, useRef, useEffect } from 'react';
import {
  Upload,
  Text,
  Button as TButton,
  Modal,
} from '@tencent/tea-component';

import { errorMsg, isValidFileName } from '@utils';
import { uuid } from '@utils/common';

const CACHE = new Map();
const UPLOAD_URL = '/api/admin/tool/cos/upload';
const ACCEPT_TYPES = ['image/png', 'image/jpeg', 'image/gif'];

export const getCache = (url) => {
  if (CACHE.has(url)) return CACHE.get(url);
  return url;
};

const getDefaultFile = (src) => {
  if (!src) return null;
  if (typeof src === 'object') return src;
  return {
    name: src.match(/([^/]+)$/)[1],
  };
};

const getHeaders = () => {
  const token = sessionStorage.getItem('token');
  if (token) {
    return {
      authorization: `Bearer ${token}`,
    };
  }
  return {};
};

const ImageUploader = ({ onSuccess, cover = null, max, action = null, onDelete, deleteCover, isCate = false }) => {
  const ref = useRef({});
  const [_file, setFile] = useState(cover);
  const [_image, setImage] = useState(cover);
  const [status, setStatus] = useState(null);
  const [percent, setPercent] = useState(null);
  const [isFrist, setIsFrist] = useState(false);
  const maxSizeMB = 6696000;
  const maxSizeKB = 102400;

  const image = getCache(_image || cover);
  const file = getDefaultFile(_file || cover);
  const data = {
    filename: '',
  };
  const getBase64 = (file) => {
    const reader = new FileReader();
    ref.current.coverPromise = new Promise((res) => {
      ref.current.coverResolve = res;
    });
    reader.onloadend = () => {
      setImage(reader.result);
      ref.current.coverResolve(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const beforeUpload = (file, fileList, isAccepted) => {
    if (!isAccepted) {
      setStatus('error');
      // errorMsg('上传图片不符合要求，请重新上传');
      const maxSize = !max ? maxSizeMB / (1024 * 1024) : maxSizeKB / 1024;
      const imgType = !max ? 'MB' : 'KB';
      Modal.alert({
        type: 'error',
        message: '错误',
        description: `上传图片不能超过${maxSize.toFixed(2)}${imgType}`,
      });
    }
    // if (!isValidFileName(file.name, ['png', 'jpeg', 'jpg', 'gif'])) {
    //   errorMsg('图片名称仅支持中英文及数字，请修改文件名称重新上传');
    //   return false;
    // }
    const suffix = file.name.split('.').pop();
    console.log('suffix22??', suffix);
    data.filename = `${uuid()}.${suffix}`;
    // data.filename = uuid() + file.name;
    console.log('---filename--img--', data.filename);
    return isAccepted;
  };

  const handleStart = (file, { xhr }) => {
    setFile(file);
    getBase64(file);
    setStatus('validating');
    ref.current.xhr = xhr;
    onSuccess && onSuccess(undefined);
  };

  const handleSuccess = (result) => {
    if (typeof result === 'object' && result !== null) {
      setStatus('success');
      ref.current.coverPromise.then((cover) => {
        const data = result.data || result;
        const fileUrl = data.file_url || data;
        CACHE.set(fileUrl, cover);
        onSuccess && onSuccess(fileUrl);
        isCate && setIsFrist(true);
        // onReStart && onReStart(true);
      });
    } else {
      setStatus('error');
      errorMsg(result.message);
    }
  };

  const handleError = () => {
    setStatus('error');
    errorMsg('图片上传发生错误');
  };

  const handleProgress = ({ percent }) => {
    setPercent(percent);
  };

  const handleAbort = (isDelete) => {
    if (ref.current.xhr) {
      ref.current.xhr.abort();
    }
    setFile(null);
    setStatus(null);
    setPercent(null);
    onSuccess && onSuccess(undefined);
    if (isDelete) {
      onDelete && onDelete(true);
    }
    // onReStart && onReStart(false);
  };
  useEffect(() => {
    deleteCover && handleAbort(true);
  }, [deleteCover]);

  return (
    <Upload
      withCredentials={false}
      maxSize={!max ? maxSizeMB : maxSizeKB}
      accept={ACCEPT_TYPES}
      onStart={handleStart}
      onProgress={handleProgress}
      onSuccess={handleSuccess}
      onError={handleError}
      beforeUpload={beforeUpload}
      action={action || UPLOAD_URL}
      className="c-image-uploader"
      headers={getHeaders()}
      data={data}
    >
      {
        ({ open, isDragging }) => (
          <Upload.Dragger
            filename={isFrist ? null : file && file.name}
            image={isFrist ? null : image}
            percent={isFrist ? null : percent}
            // description={
            //   file && file.size && (
            //     <p>
            //       文件大小：
            //       {Math.floor(file.size / 1024)}
            //       K
            //     </p>
            //   )
            // }
            button={
              isFrist ? null : status === 'validating' ? (
                <TButton htmlType="button" type="link" onClick={() => handleAbort(false)}>
                  取消上传
                </TButton>
              ) : (
                <div>
                  <TButton htmlType="button" type="link" onClick={open}>
                    重新上传
                  </TButton>
                  <TButton htmlType="button" type="link" onClick={() => handleAbort(true)} style={{ marginLeft: 8 }}>
                    删除
                  </TButton>
                </div>
              )
            }
          >
            {
              isDragging ? '释放鼠标' : (
                <span>
                  <a onClick={open}>点击上传</a>
                  <Text theme="weak">/拖拽到此区域</Text>
                </span>
              )
            }
          </Upload.Dragger>
        )
      }
    </Upload>
  );
};

export default ImageUploader;

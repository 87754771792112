import React, { Suspense } from 'react';

const Loading = () => (
    <div className="component-loading">
        加载中……
    </div>
);

export default ({ children }) => (
    <Suspense fallback={<Loading/>}>
        {children}
    </Suspense>
);

export default {
  create: 'POST /account/create',
  org: 'POST /org/create',
  getCodes: 'POST /tool/sms/sendcode',
  getTable: 'GET /extra/project/check/logs',
  getAdminList: 'GET /account/admin/list',
  getGroupList: 'GET /account/group/list', // 经销商列表
  createGroup: 'POST /account/group/create', // 经销商创建
  getGroupDetail: 'GET /account/group/detail', // 经销商详情
  GroupUpdate: 'POST /account/group/update', // 经销商编辑
  getAccountList: 'GET /account/list', // 销售列表
  getGroupProjectList: '/extra/project/list',
  getStaffList: '/extra/account/stafflist', // 置业顾问管理列表
  propertyList: 'get /account/subgroup/list',  // 集团楼盘列表
  adgroupList: 'get /account/adgroup/list', // 所属团队
};

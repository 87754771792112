import React, { useState, useEffect } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { renderRoutes as render } from 'react-router-config';
import {
  HashRouter as Router,
  useLocation,
} from 'react-router-dom';
import { useUpdateAtom } from 'jotai/utils';
import { Dialog } from '@tencent/spaui';

import 'common/style/index.scss';
import '@components/style.scss';
import { getRoutes } from './routes';
import { decode, logout } from '@utils';
import { uid } from './atoms';
import store from './store';
// import api from 'api';

if (process.env.NODE_ENV !== 'production') {
  require('@tencent/spaui-business/lib/styles/css/index.css');
  require('@tencent/spaui/lib/styles/css/index.css');
}

// 使用 hooks 无法获取到 ticket，另想办法
const getTicket = () => {
  /**
   * 坑点：
   * 使用了 hash 路由之后，query 是空的，？后面的算 hash
   */
  const { href } = window.location;
  const matched = href.match(/[?&]ticket=([^&=]+)/);
  return (matched || [])[1];
};

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const system = useSelector(({ system }) => system);
  const user = useSelector(({ user }) => user);
  const systemStatus = system.status;
  const { menu } = system;
  const ticket = getTicket();
  const setUid = useUpdateAtom(uid);
  const [abandon, setAbandon] = useState(false);

  const handleLogout = () => {
    logout().finally(() => {
      setAbandon(false);
      sessionStorage.removeItem('group');
      dispatch('LOGOUT');
    });
  };

  useEffect(() => {
    if (systemStatus !== 'logined') return;
    // 获取广告主账号
    const userCache = sessionStorage.getItem('_cache_user_info');
    if (!userCache) console.error('账号信息获取失败');
    const userInfo = decode(userCache);
    setUid(userInfo.ad_account_id);
    const loginType = localStorage.getItem('_cache_login_type');
    if (userInfo.ad_account_id && loginType === 'phone' && userInfo.type !== 'system') {
      setAbandon(true);
    }
    dispatch({
      type: 'UPDATE_ACCOUNT',
      payload: { adUser: userInfo.ad_account_id },
    });
  }, [systemStatus, dispatch]);

  // 讯达融合需求，使用 SSO 完成登录后跳转到前端
  // SSO 会把 sso_ticket 带到 search 字段，不支持哈希路由的 search
  // 这里把 history 的 search 转化成 hash 的 search
  // 暂未处理之前关联到 history 的 search 逻辑
  // 从需求上来说用到 history search 的只有 跳转 登录
  // 但是房讯达之前废弃掉了 SSO 登录
  // 融合的需求又把 SSO 登录加回来了，但是这次是 redirect 到前端
  // 融合使用新逻辑，理论上不会对已废弃的 history search 的使用产生影响
  if (window.location.search) {
    const qs = new URLSearchParams(window.location.search);
    if (qs.has('target')) {
      const pathname = qs.get('target');
      qs.delete('target');
      const $qs = new URLSearchParams();
      const _qs = new URLSearchParams(location.search);
      const fn = (queryParams) => {
        for (const [key, value] of queryParams) {
          $qs.set(key, value);
        }
      };
      fn(_qs);
      fn(qs);
      const queryString = $qs.toString();
      let targetURL = `${window.location.origin}/#${pathname}`;
      if (queryString) {
        targetURL = `${targetURL}?${queryString}`;
      }
      window.location.href = targetURL;
      return null;
    }
  }

  if (systemStatus !== 'logined' && ticket) return '加载中...';
  return (
    <>
      { render(getRoutes(systemStatus === 'logined', menu)) }
      {
        false && user.type !== 'system' && (
          <Dialog
            backdrop
            title=" "
            type="normal"
            show={abandon}
            className="spaui-dialog-confirm-normal"
            closeButton={null}
            cancelButton={null}
            onSubmit={handleLogout}
          >
            <div
              style={{
                fontSize: '22px',
                fontWeight: 'bold',
                lineHeight: '1.5em',
                textAlign: 'left',
              }}
            >
              <p>！不支持编辑</p>
              <p>！请使用注册的微信号扫码登录</p>
            </div>
          </Dialog>
        )
      }
    </>
  );
};

export default () => (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

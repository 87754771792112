import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import Promise from 'bluebird';

const init = () => {
  // 替换 Promise，需要能中断 Promise 链的三方版本
  Promise.config({
    cancellation: true,
    warnings: false,
  });
  window.Promise = Promise;

  const isProd = process.env.NODE_ENV === 'production';
  if (isProd && window.location.protocol === 'http:') {
    console.log('not https, redirect to https.');
    window.location.protocol = 'https:';
    return false;
  }
  return true;
};

try {
  init() && ReactDOM.render(
    <App />,
    document.getElementById('root'),
  );
} catch (e) {
  console.log('系统发生错误:', e.message);
  sessionStorage.clear();
}

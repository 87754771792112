import React, { useMemo, useState, useEffect } from 'react';
import { Breadcrumb } from '@tencent/tea-component';
import { Link } from 'react-router-dom';
import { renderRoutes, matchRoutes } from 'react-router-config';

import './layout.scss';
import api from 'api';
import { getUserInfo } from '@utils';
import Nav from './Nav';
import Menu from './Menu';

const { Item } = Breadcrumb;

const hasChildMenu = (route) => {
  if (Array.isArray(route.routes) && route.routes.length) {
    return route.routes.some(r => r.isMenu);
  }
  return false;
};

const Layout = ({ route, location, dispatch }) => {
  const [org, setOrg] = useState({});
  const [showProject, setShowProject] = useState(false);
  const breads = useMemo(() => {
    const matchedRoutes = matchRoutes(route.routes, location.pathname);
    const arr = matchedRoutes.filter(({ route }) => !!route.title)
      .map(({ route }) => ({
        title: route.title,
        url: !hasChildMenu(route) && route.path,
      }));
    if (arr.length) {
      delete arr[arr.length - 1].url;
    }
    console.log(arr, 'arr');
    return arr.map(({ url, title }, i) => {
      if (url) {
        return (
            <Item key={i}>
              <Link to={url}>{ title }</Link>
            </Item>
        );
      }
      return (<Item key={i}>{ title }</Item>);
    });
  }, [route.routes, location.pathname]);

  useEffect(() => {
    const {
      org_id: oid,
      group_id: gid,
      type,
    } = getUserInfo();
    let fn = null;
    let params = null;
    if (type === 'organization') {
      fn = api('default').getOrgInfo;
      params = { organization_id: oid };
    }
    if (['group', 'staff'].includes(type)) {
      fn = api('default').getGrpInfo;
      params = { group_id: gid };
    }
    if (fn && params) {
      const opt = {
        silence: true,
        canNotCancel: true,
      };
      fn(params, opt).then((data) => {
        setOrg(data || {});
        dispatch({
          type: 'SET_GROUP',
          payload: data,
        });
        if (process.env.NODE_ENV !== 'production') {
          console.log(data);
        }
      });
    }
    if (type === 'system') {
      setOrg({ short_name: '超管平台' });
    }
  }, [dispatch]);
  const urlWhiteList = [
    '/project-center/project/key',
    '/project-center/project/application/edit/',
  ];
  useEffect(() => {
    const hasUrl = urlWhiteList.some(item => item === location.pathname);
    const editUrl = location.pathname.startsWith(urlWhiteList[1]);
    hasUrl || editUrl ? setShowProject(true) : setShowProject(false);
    return () => {
      setShowProject(false);
    };
  }, [location.pathname]);
  return (
    !showProject ? (<div className="layout">
      <Menu routes={route.routes} org={org} />
      <Nav org={org} />
      <main>
        <div className="layout-bread">
          {
            location.pathname
            && location.pathname !== '/system/viewannment'
            && !(location.pathname.startsWith('/system/viewannment/detail')) && (<Breadcrumb>{ breads }</Breadcrumb>)
          }
          {
            location.pathname
            && location.pathname === '/system/viewannment'
            && !(location.pathname.startsWith('/system/viewannment/detail')) && (<div style={{
              color: '#fff',
              fontWeight: 'bold',
            }}>公告列表</div>)
          }
          {
            location.pathname
              && location.pathname.startsWith('/system/viewannment/detail') && (<div style={{
              color: '#fff',
              fontWeight: 'bold',
            }}>公告详情</div>)
          }
        </div>
        { renderRoutes(route.routes) }
      </main>
    </div>) : renderRoutes(route.routes)
  );
};

export default Layout;

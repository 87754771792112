import React, { useState, useRef } from 'react';
import {
  Upload,
  Button as TButton,
} from '@tencent/tea-component';

import { errorMsg, isValidFileName } from '@utils';
import './index.scss';
import { uuid } from '@utils/common';

const CACHE = new Map();
const UPLOAD_URL = '/api/admin/tool/cos/upload';
const ACCEPT_TYPES = ['image/png', 'image/jpeg', 'image/gif'];

export const getCache = (url) => {
  if (CACHE.has(url)) return CACHE.get(url);
  return url;
};

// const getDefaultFile = src => {
//   if (!src) return null;
//   if (typeof src === 'object') return src;
//   return {
//     name: src.match(/([^/]+)$/)[1]
//   };
// };

const getHeaders = () => {
  const token = sessionStorage.getItem('token');
  if (token) {
    return {
      authorization: `Bearer ${token}`,
    };
  }
  return {};
};

const ImageUploaderPro = ({ onSuccess, cover = null, max = 1024 * 2, isUpdate = false }) => {
  const ref = useRef({});
  const [, setFile] = useState(cover);
  const [_image, setImage] = useState(cover);
  const [status, setStatus] = useState(null);
  const [percent, setPercent] = useState(null);

  const image = getCache(_image || cover);
  // const file = getDefaultFile(_file || cover);

  const data = {
    filename: '',
  };
  const getBase64 = (file) => {
    const reader = new FileReader();
    ref.current.coverPromise = new Promise((res) => {
      ref.current.coverResolve = res;
    });
    reader.onloadend = () => {
      setImage(reader.result);
      ref.current.coverResolve(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const beforeUpload = (file, fileList, isAccepted) => {
    if (!isAccepted) {
      setStatus('error');
      errorMsg('上传图片不符合要求，请重新上传');
    }

    const suffix = file.name.split('.').pop();
    console.log('suffix22??', suffix);
    if (!['png', 'jpeg', 'jpg', 'gif'].includes(suffix)) {
      errorMsg('图片文件仅支png, jpeg, jpg, gif格式');
      return false;
    }


    data.filename = `${uuid()}.${suffix}`;
    console.log('imageUploader-data.filename?', data.filename)
    // if (!isValidFileName(file.name, ['png', 'jpeg', 'jpg', 'gif'])) {
    //   errorMsg('图片名称仅支持中英文及数字，请修改文件名称重新上传');
    //   return false;
    // }
    // data.filename = uuid() + file.name;
    console.log('---filename----', data.filename);
    return isAccepted;
  };

  const handleStart = (file, { xhr }) => {
    setFile(file);
    getBase64(file);
    setStatus('validating');
    ref.current.xhr = xhr;
    onSuccess && onSuccess(undefined);
  };

  const handleSuccess = (result) => {
    if (typeof result === 'object' && result !== null) {
      setStatus('success');
      ref.current.coverPromise.then((cover) => {
        const data = result.data || result;
        CACHE.set(data.file_url, cover);
        onSuccess && onSuccess(data.file_url);
      });
    } else {
      setStatus('error');
      errorMsg(result.message);
    }
  };

  const handleError = () => {
    setStatus('error');
    errorMsg('图片上传发生错误');
  };

  const handleProgress = ({ percent }) => {
    console.log(percent, 'percent');
    setPercent(percent);
  };

  const handleAbort = () => {
    if (ref.current.xhr) {
      ref.current.xhr.abort();
    }
    setFile(null);
    setStatus(null);
    setPercent(null);
    onSuccess && onSuccess(undefined);
  };

  return (
    <Upload
      withCredentials={false}
      maxSize={1024 * max}
      accept={ACCEPT_TYPES}
      onStart={handleStart}
      onProgress={handleProgress}
      onSuccess={handleSuccess}
      onError={handleError}
      beforeUpload={beforeUpload}
      action={UPLOAD_URL}
      className="c-image-uploaderpro"
      headers={getHeaders()}
      data={data}
    >
      {
        ({ open, isDragging }) => (
          <Upload.Dragger
            // filename={file && file.name}
            image={image}
            percent={percent}
            // description={
            //   file && file.size && (
            //     <p>
            //       文件大小：
            //       {Math.floor(file.size / 1024)}
            //       K
            //     </p>
            //   )
            // }
            button={
              status === 'validating' ? (
                <TButton type="link" onClick={handleAbort}>
                  取消上传
                </TButton>
              ) : (
                <>
                  <TButton type="link" onClick={open}>
                    重新上传
                  </TButton>
                  <TButton type="link" onClick={handleAbort} style={{ marginLeft: 8 }}>
                    删除
                  </TButton>
                </>
              )
            }
          >
            {
              isDragging ? '释放鼠标' : (
                <><a onClick={open} style={{ fontSize: 60, color: '#5A66FF' }}>+</a>
                  <br/>
                  <a onClick={open}>{ isUpdate ? '更换' : '添加' }图片</a>
                  {/* <Text theme="weak">/拖拽到此区域</Text> */}
                </>
              )
            }
          </Upload.Dragger>
        )
      }
    </Upload>
  );
};

export default ImageUploaderPro;

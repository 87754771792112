export default {
  checkCode: 'GET /extra/project/key/check',
  getKey: '/extra/project/key/ask',
  getlist: 'GET /extra/project/list',
  searchVersions: 'POST /account/group/search_versions', // 获取版信息
  upDateVersions: 'POST /account/group/update_versions', // 更新版信息
  upload: 'POST /extra/tool/upload',
  // upload: 'POST /tool/cos/upload',
  update: 'POST /extra/project/apply',
  getTable: 'GET /extra/project/check/logs',
  checkStatus: 'GET /extra/project/updatestatus',
  updateCheckStatus: 'GET /extra/project/updatecheckstatus',
  projectDetail: 'GET /extra/project/detail',
  checkqqwx: 'POST /extra/project/checkqqwx',
  getAdminList: 'GET /account/admin/list',
  getGroupList: 'GET /account/group/list', // 经销商列表
  createGroup: 'POST /account/group/create', // 经销商创建
  getGroupDetail: 'GET /account/group/detail', // 经销商详情
  GroupUpdate: 'POST /account/group/update', // 经销商编辑
  getAccountList: 'GET /account/list', // 销售列表
  getGroupProjectList: '/extra/project/list',
  getStaffList: '/extra/account/stafflist', // 置业顾问管理列表
  getGroupContent: 'get /extra/project/groupcontent/detail',
  checkAdAccount: 'POST /extra/project/checkAdAccount', // 校验广告主账号
  // 服务商列表
  getCompanyList: '/account/public/company/list',
  propertyList: 'get /account/subgroup/list',  // 集团楼盘列表
  batchChangeStatus: 'post /account/subgroup/batchchangestatus',  // 房讯达集团版专属接口，批量上下架楼盘

  getList: '/content/list',
  activityList: 'get /content/activity/list',
  activityUpdate: 'post /content/activity/update',
  activityDetail: 'get /content/activity/detail',
  activityCreate: 'post /content/activity/create',
  activityDelete: 'post /content/activity/delete',
  updateContent: 'post /content/update',
  createContent: 'post /content/create',
  deleteContent: 'post /content/delete',
  getDetail: '/content/detail',
  getStores: '/account/group/locationlist',
  getCityCode: 'get /extra/address/geocoder',
  // createGroupContent: 'get /extra/project/groupcontent/create',
  changeHousePrice: 'get /extra/project/updateprice',
  // createContentRelation: '/extra/project/contentrelation/create',
  checkContentRelation: '/extra/project/contentrelation/check',
  createProperty: 'post /account/subgroup/create',
  mapAll: 'get /account/map/all',  // 获取所有省、市、区

  getAccountTicket: '/account/prechange', // 换绑之前获取 ticket
  changeBind: 'POST /account/changebind', // 换绑
  adgroupList: 'get /account/adgroup/list', // 所属团队
};

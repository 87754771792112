import React, { useState, useEffect, useCallback } from 'react';
import { TopNav, Select } from '@tencent/spaui';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '@utils';
import api from 'api';

const AVATAR = 'https://fxd-1256737511.file.myqcloud.com/fang/temp/c8b3k2k7kio0user.png';

const LogoComponent = ({ href, className }) => (
        <Link to={href} className={className}>
            {/* <LogoIcon width="32" /> */}
            <div style={{ marginLeft: 24, marginRight: 11 }}>
                <img style={{
                  width: 30,
                  height: 30,
                }} src="https://fxd-1256737511.file.myqcloud.com/fang/temp/f42202ab5b40252693771187a1d085bc"/>
            </div>
        </Link>
);


const TitleComponent = withRouter(({ history, children, href, className }) => {
  const dispatch = useDispatch();
  const group = useSelector(state => state.group);
  const user = useSelector(s => s.user);
  const [groupOptionsList, setOptionsList] = useState(null); // 请求获取的options
  const [options, setOptions] = useState([]);
  const fetchGroupList = api('default').getGroupList;
  const fetchAnnouncementnewone = api('default').getAnnouncementnewone;
  const [announce, setAnnounce] = useState(null);
  const [getannounce, setGetannounce] = useState(false);

  // 获取最新发布的消息
  const getNewAnnouncement = () => {
    // console.log('获取最新的公告消息');
    fetchAnnouncementnewone().then((res) => {
      // console.log('getNewAnnouncement', res);
      setAnnounce(res);
    });
  };

  // 获取活动列表
  const getGroupList = useCallback(() => {
    fetchGroupList().then((res) => {
      const hasGroupList = res.list && res.list.filter(item => item.group_id);
      setOptionsList(hasGroupList);
      setOptions((state) => {
        state = hasGroupList.map((item, index) => ({
          label: item.group_name,
          value: index,
        }));
        return state;
      });
    });
  }, [fetchGroupList]);

  // 切换项目
  const changeCheckGroup = (checkItem) => {
    const data = groupOptionsList[checkItem];
    api('default').changeCheckGroup(data)
      .then((res) => {
        dispatch({
          type: 'LOGIN',
          payload: res,
        });
      });
  };

  useEffect(() => {
    // getGroupList();
  }, [getGroupList]);

  useEffect(() => () => {
    if (!getannounce) {
      setGetannounce(true);
      // 抓取最新的公告
      getNewAnnouncement();
    }
  }, [getNewAnnouncement]);

  const logoUrl = 'https://fxd-1256737511.file.myqcloud.com/fang/temp/f42202ab5b40252693771187a1d085bc';
  return (
    <>
      <Link to={href} className={className}>
        {children}
      </Link>
      { user.role !== 'system' && (<div id="navtitle-container-group">
          <div className="logo">
            {
              group && group.group.logo_url ? <img src={group.logo_url} /> : <img src={logoUrl}/>
            }
          </div>
          <div className="name">{user.bloc_name}</div>
          {
            announce && announce.name && (<div className="annicon"><span className="anniconspan">i</span></div>)
          }
          {
            announce && announce.name && (
              <div className="announcenew" style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                wordBreak: 'normal',
                maxWidth: '300px',
              }} onClick={() => {
                history.push(`/system/viewannment/detail/${announce.id}`);
              }}>{announce.name}</div>
            )
          }
          {
            announce && announce.name && (
              <div className="announcenewmore" onClick={() => {
                history.push('/system/viewannment');
              }}>{'更多公告消息 >>'}</div>
            )
          }

          <div className="check" onClick={() => getGroupList()} style={{ display: 'none' }}>
            {
              options.length > 1 ? <Select
                placeholder="切换项目"
                data={options}
                onChange={(e, val) => {
                  e.stopPropagation();
                  changeCheckGroup(val);
                }}
              /> : null
            }
          </div>
        </div>
      )}
    </>
  );
});

const Nav = ({ org }) => {
  const dispatch = useDispatch();
  const { name } = useSelector(state => state.user);
  const data = {
    title: '腾讯广告鹅房通',
    rootUrl: '/',
    list: [{
      placement: 'right',
      data: [{
        type: 'userinfo',
        nickname: name,
        txUrl: org.logo_url || AVATAR,
        data: [{
          label: '退出登录',
          onClick: () => {
            // const ticket = localStorage.getItem('_cache_xunda_ticket');
            // window.navigator.sendBeacon(`/api/admin/account/sso/logout?xunda_ticket=${ticket}`);
            logout().finally(() => {
              sessionStorage.removeItem('group');
              dispatch('LOGOUT');
            });
          },
        }],
      }],
    }],
  };

  return (
        <div className="layout-nav">
            <TopNav
                {...data}
                LogoComponent={LogoComponent}
                LogoTitleComponent={TitleComponent}
                style={{ position: 'relative' }}
            />
        </div>
  );
};

export default Nav;

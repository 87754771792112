import {
  createStore,
  combineReducers,
  applyMiddleware,
} from 'redux';

import { cancel, setToken } from 'api';
import { encode, decode, noAdAccountAlert } from '@utils';

const HOME = '/home';

const userCache = sessionStorage.getItem('_cache_user_info');
const menuCache = sessionStorage.getItem('_cache_menu_list');

const getEntryPath = () => {
  const { location } = window;
  const { hash } = location;
  // console.log('entrypath hash:', hash)
  const pathname = hash.replace('#', '');
  let [path, query] = pathname.split('?');
  if (query) {
    query = new URLSearchParams(query);
    query.delete('ticket');
    query = query.toString();
    if (query) {
      path = `${path}?${query}`;
    }
  }
  // console.log('entryPath search:', search)
  // console.log('entrypath pathname:', pathname)
  if (path === '/login') return HOME;
  return path;
};

const defaultState = {
  system: {
    status: userCache ? 'logined' : 'unlogin',
    entryPath: getEntryPath(),
    menu: menuCache ? decode(menuCache) : [],
  },
  user: !userCache ? {} : decode(userCache),
  account: { adUser: null },
  group: null,
  sequence: {
    huxing: false,
    activity: false,
  },
};

const userReducer = (state = defaultState.user, action) => {
  const { payload } = action;
  /**
 * user = {
 *    name: string
 *    type: string (system | organization | group)
 * }
 */
  switch (action.type) {
    case 'LOGIN': {
      const user = payload.token.split('.')[1];
      setToken(payload.token);
      sessionStorage.setItem('token', payload.token);
      sessionStorage.setItem('_cache_user_info', user);
      const userData = decode(user);
      window.getUser = () => userData;
      // console.log(userData, 'userData');
      if (userData.role !== 'system' && !userData.ad_account_id) noAdAccountAlert(userData.ad_user_id);
      return userData;
    }
    case 'LOGOUT': {
      // const apiPath = '/api/admin/account/sso/logout';
      // const url = new URL(apiPath, window.location.origin);
      // const ticket = localStorage.getItem('_cache_xunda_ticket');
      // url.searchParams.set('xunda_ticket', ticket);
      // const token = sessionStorage.getItem('token');
      // const headers = new Headers({ authorization: `Bearer ${token}` });
      // fetch(url, { headers, method: 'POST' });
      sessionStorage.clear();
      // 重定向到登录首页
      window.location.href = '/';
      return {};
    }
    case 'BIND_AD_ACCOUNT': {
      const newUser = { ...state, ad_account_id: payload };
      // 防止其他地方从缓存读取而导致数据不同步，更新缓存
      sessionStorage.setItem('_cache_user_info', encode(newUser));
      return newUser;
    }
    case 'CHANGE_ROLE':
      return {
        ...state,
        type: action.payload,
      };
    case 'ORDER_INDEX':
      return {
        ...state,
        sequence: action.payload,
      };
    default:
      return state;
  }
};

const groupReducer = (state = defaultState.group, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'SET_GROUP':
      return {
        ...state,
        group: payload,
      };
    default:
      return state;
  }
};

const systemReducer = (state = defaultState.system, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'CANCEL_REQUEST':
      state.status === 'logined' && cancel();
      return state;
    case 'LOGIN': {
      const { type } = decode(payload.token.split('.')[1]);
      const pathMap = {
        system: '/project-center/project',
        organization: '/marketing/propertiesList?sort=desc',
        group: '/marketing/propertiesList?sort=desc',
        staff: '/business/clue',
      };
      // console.log('当前登录账号类型：', type)
      const defaultPath = pathMap[type];
      // const ep = state.entryPath
      // console.log('entryPath:', state.entryPath)
      // console.log('defaultPath:', defaultPath)
      // console.log(
      //   'final path:',
      //   (ep === HOME || ep === '/' || ep === '')
      //   ? defaultPath
      //   : ep
      // )
      sessionStorage.setItem('token', payload.token);
      sessionStorage.setItem('_cache_menu_list', encode(payload.menus));
      return {
        ...state,
        status: 'logined',
        menu: payload.menus || [],
        // entryPath: (ep === HOME || ep === '/' || ep === '') ? defaultPath : ep
        entryPath: defaultPath,
      };
    }
    case 'LOGOUT':
      return {
        status: 'unlogin',
        // entryPath: getEntryPath()
      };
    default:
      return state;
  }
};

const accountReducer = (state = defaultState.account, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'UPDATE_ACCOUNT':
      return ({ ...state, ...payload });
    default:
      return state;
  }
};
const sequenceReducer = (state = defaultState.sequence, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'UPDATE_CITY':
      return ({ ...state, ...payload });
    default:
      return state;
  }
};
const reducer = combineReducers({
  account: accountReducer,
  system: systemReducer,
  user: userReducer,
  group: groupReducer,
  sequence: sequenceReducer,
});

// 将 string 类型的 action 转化成标准 action
export const stringTransfer = () => next => (action) => {
  if (typeof action === 'string') {
    action = { type: action };
  }
  return next(action);
};

const store = createStore(
  reducer,
  defaultState,
  applyMiddleware(stringTransfer),
);

export const { dispatch } = store;

export default store;

/*
 * @Author: 菲菲呀
 * @Date: 2021-04-08 11:47:56
 * @LastEditors: 菲菲呀
 * @LastEditTime: 2021-05-11 14:41:29
 * @Description:
 */
export default {
  getAccountList: 'GET /account/vmerge/list',
  createAccount: 'POST /account/vmerge/create',
  updateAccount: 'POST /account/vmerge/update',
  createAdmin: 'POST /account/create',
  updateAdmin: 'POST /account/update',
  deleteAccount: 'POST /account/delete',
  getDetailAccount: 'GET /account/detail',
  getOrgList: 'GET /account/org/list',
  getRoleList: 'GET /account/role/list',
  getAuthState: '/account/wechat/authinfo',
  checkBind: '/account/sso/checkbind',
  accountBind: 'POST /account/sso/bindadaccount',
  getRoleTemp: '/account/role/template',
  getRoleTempList: '/account/role/template/list',
  createRoleTemp: 'POST /account/role/template/create',
  updateRoleTemp: 'POST /account/role/template/update',
  getMenu: '/account/role/defaultmenus',
  create: 'post /account/create',
  org: 'post /org/create',
  getCodes: 'post /account/sendcode',
  updatePassword: 'post /account/updatepwd',
  updateContent: 'get /extra/lpr/set',
  getDetail: 'get /extra/lpr/detail',
  getGroupList: '/account/admin/list',
  getGroupProjectList: '/extra/project/list',
  updrelation: '/extra/account/updrelation',
  stopAccount: 'post /account/staff/status',
  getAnnouncementList: 'GET /extra/announcement/list',
  getDetailAnnouncement: 'GET /extra/announcement/detail',
  createAnnouncement: 'POST /extra/announcement/create',
  updateAnnouncement: 'POST /extra/announcement/update',
  deleteAnnouncement: 'POST /extra/announcement/delete',
  stopAnnouncement: 'POST /extra/announcement/status',
  getAnnouncementnewone: 'GET /extra/announcement/newone',
  getAnnouncementShowlist: 'GET /extra/announcement/showlist',
  checkAdAccount: 'POST /extra/project/checkAdAccount', // 校验广告主账号
  getSimplecontentDetail: 'get /extra/simplecontent/details',
  saveSimplecontentDetail: 'post /extra/simplecontent/save',
  propertyList: 'get /account/subgroup/list',  // 集团楼盘列表
  getAdTeamList: '/account/adgroup/list',
  checkDeletable: '/account/delete/check',
  getEmployeeDownloadSrc: '/account/export',
  batchCreateEmployee: 'POST /account/import',
  getLinkList: '/account/adgroup/relationlist',
  updateTeamRelation: 'POST /account/adgroup/bind',
};

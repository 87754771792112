export default {
  getList: '/content/list',
  activityList: 'get /content/activity/list',
  activityUpdate: 'post /content/activity/update',
  activityDetail: 'get /content/activity/detail',
  activityCreate: 'post /content/activity/create',
  activityDelete: 'post /content/activity/delete',
  updateContent: 'post /content/update',
  createContent: 'post /content/create',
  deleteContent: 'post /content/delete',
  getDetail: '/content/detail',
  getStores: '/account/group/locationlist',
  getCityCode: 'get /extra/address/geocoder',
  getGroupContent: 'get /extra/project/groupcontent/detail',
  createGroupContent: 'get /extra/project/groupcontent/create',
  changeHousePrice: 'get /extra/project/updateprice',
  createContentRelation: '/extra/project/contentrelation/create',
  checkContentRelation: '/extra/project/contentrelation/check',
  createProperty: 'post /account/subgroup/create',
  propertyList: 'get /account/subgroup/list',  // 集团楼盘列表
  updateProperty: 'POST /account/subgroup/update',  // 更新集团楼盘详情
  propertyRemove: 'get /account/subgroup/delete',  // 集团楼盘列表删除
  checkList: 'get /content/collection/checkbind',  // 检查内容是否绑定了集合页
  getContentList: 'get /extra/project/contentList',  // 楼盘内容请求   获取集合页绑定楼盘的信息
  mapAll: 'get /account/map/all',  // 获取所有省、市、区
  checkStatus: 'GET /extra/project/updatestatus',
};
